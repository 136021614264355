<template>
    <div>
        <div class="timeline-items__right rounded-sm w-100 p-4 bg-oq">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="text-descr d-flex mb-4">
                        <span class="d-block mr-3"> Наименование: </span>
                        <div class="d-flex align-center font-medium">
                            Samsung RB30N4020B1/WT
                        </div>
                    </div>
                    <div class="text-descr d-flex mb-4">
                        <span class="d-block mr-3"> Товар: </span>
                        <div class="d-flex align-center font-medium">
                            Кондиционер
                        </div>
                    </div>
                    <div class="text-descr d-flex mb-4">
                        <span class="d-block mr-3"> Серийный номер: </span>
                        <div class="d-flex align-center font-medium">
                            CXZC3454CX
                        </div>
                    </div>
                    <div class="text-descr d-flex mb-4">
                        <span class="d-block mr-3">
                            Дата истечения срока гарантии:
                        </span>
                        <div class="d-flex align-center font-medium">
                            12.12.2020
                        </div>
                    </div>
                    <div class="text-descr d-flex mb-4">
                        <span class="d-block mr-3">Клиент: </span>
                        <div class="d-flex align-center font-medium">
                            Ахмад ака
                        </div>
                    </div>
                </el-col>

                <el-col :span="12">
                    <div class="text-descr d-flex mb-4">
                        <span class="d-block mr-3">
                            Класс энергопотребления:
                        </span>
                        <div class="d-flex align-center font-medium">A+</div>
                    </div>
                    <div class="text-descr d-flex mb-4">
                        <span class="d-block mr-3"> Дата продажи: </span>
                        <div class="d-flex align-center font-medium">
                            12.12.2020
                        </div>
                    </div>
                    <div class="text-descr d-flex mb-4">
                        <span class="d-block mr-3"> Гарантия: </span>
                        <div class="d-flex align-center font-medium">
                            3 года
                        </div>
                    </div>
                    <div class="text-descr d-flex mb-4">
                        <span class="d-block mr-3">Адрес: </span>
                        <div class="d-flex align-center font-medium">
                            Ташкент
                        </div>
                    </div>
                    <div class="text-descr d-flex mb-4">
                        <span class="d-block mr-3">Телефон: </span>
                        <div class="d-flex align-center font-medium">
                            998990090099
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-form ref="form" :model="form">
            <div class="timeline-items__right rounded-sm w-100 p-4 bg-oq mt-5">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="text-descr d-flex mb-4">
                            <span class="d-block mr-3"> 1: </span>
                            <div class="d-flex align-center font-medium">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.?
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="1"
                            >Test A</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="2"
                            >Test B</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="3"
                            >Test C</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="4"
                            >Test E</el-radio
                        >
                    </el-col>
                    <el-col :span="12">
                        <div class="d-flex" style="align-items: center">
                            <el-input
                                placeholder="Напишите что нибудь..."
                                v-model="form.input"
                                class="mr-2"
                            ></el-input>
                            <el-radio v-model="form.radio" label=""></el-radio>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="timeline-items__right rounded-sm w-100 p-4 bg-oq mt-5">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="text-descr d-flex mb-4">
                            <span class="d-block mr-3"> 1: </span>
                            <div class="d-flex align-center font-medium">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.?
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="1"
                            >Test A</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="2"
                            >Test B</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="3"
                            >Test C</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="4"
                            >Test E</el-radio
                        >
                    </el-col>
                    <el-col :span="12">
                        <div class="d-flex" style="align-items: center">
                            <el-input
                                placeholder="Напишите что нибудь..."
                                v-model="form.input"
                                class="mr-2"
                            ></el-input>
                            <el-radio v-model="form.radio" label=""></el-radio>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="timeline-items__right rounded-sm w-100 p-4 bg-oq mt-5">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="text-descr d-flex mb-4">
                            <span class="d-block mr-3"> 1: </span>
                            <div class="d-flex align-center font-medium">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.?
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="1"
                            >Test A</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="2"
                            >Test B</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="3"
                            >Test C</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="4"
                            >Test E</el-radio
                        >
                    </el-col>
                    <el-col :span="12">
                        <div class="d-flex" style="align-items: center">
                            <el-input
                                placeholder="Напишите что нибудь..."
                                v-model="form.input"
                                class="mr-2"
                            ></el-input>
                            <el-radio v-model="form.radio" label=""></el-radio>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="timeline-items__right rounded-sm w-100 p-4 bg-oq mt-5">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="text-descr d-flex mb-4">
                            <span class="d-block mr-3"> 1: </span>
                            <div class="d-flex align-center font-medium">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.?
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="1"
                            >Test A</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="2"
                            >Test B</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="3"
                            >Test C</el-radio
                        >
                    </el-col>
                    <el-col :span="3">
                        <el-radio v-model="form.radio" label="4"
                            >Test E</el-radio
                        >
                    </el-col>
                    <el-col :span="12">
                        <div class="d-flex" style="align-items: center">
                            <el-input
                                placeholder="Напишите что нибудь..."
                                v-model="form.input"
                                class="mr-2"
                            ></el-input>
                            <el-radio v-model="form.radio" label=""></el-radio>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer mt-3 d-block text-center">
            <el-button type="primary" @click="centerDialogVisible = false">
                Сохранить
            </el-button>
        </span>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                radio: "1",
            },
        };
    },
};
</script>
