<template>
    <div class="texno-khan-o">
        <div class="card-table-body ifon-add-title-block">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="caz-blocks-sarcho-title">
                        <div
                            class="content-title d-flex align-center mr-2"
                            :class="
                                mode
                                    ? 'content__titleday'
                                    : 'content__titlenight'
                            "
                        >
                            Happy call
                        </div>
                        <div class="block-sarche">
                            <div class="header__search d-flex">
                                <crm-input
                                    :size="'small'"
                                    :className="'w100'"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    v-model="search"
                                    :icon="'el-icon-search'"
                                    class="mr-3"
                                    placeholder="ФИО"
                                    style="width: 220px"
                                ></crm-input>
                                <crm-input
                                    :size="'small'"
                                    :className="'w100'"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    v-model="search"
                                    :icon="'el-icon-search'"
                                    class="mr-3"
                                    style="width: 220px"
                                    placeholder="Номер телефона"
                                ></crm-input>
                                <crm-input
                                    :size="'small'"
                                    :className="'w100'"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    v-model="search"
                                    :icon="'el-icon-search'"
                                    class="mr-3"
                                    style="width: 220px"
                                    placeholder="CXZC3454CX"
                                ></crm-input>
                                <el-date-picker
                                    v-model="value1"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    size="small"
                                    type="date"
                                    class="mr-3"
                                    placeholder="От"
                                >
                                </el-date-picker>
                                <el-date-picker
                                    v-model="value1"
                                    size="small"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    type="date"
                                    placeholder="До"
                                >
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- end ifon-add-title-block -->

        <div class="card-table-header table-crm-smart mt-0 pt-0">
            <table
                class="table-my-code table-bordered mt-0 pt-0"
                :class="mode ? 'table__myday' : 'table__mynight'"
                v-loading="loadingData"
            >
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.napeProduct.show">
                            {{ columns.napeProduct.title }}
                        </th>

                        <th v-if="columns.gruppa.show">
                            {{ columns.gruppa.title }}
                        </th>

                        <th v-if="columns.serialNumber.show">
                            {{ columns.serialNumber.title }}
                        </th>

                        <th v-if="columns.clasE.show">
                            {{ columns.clasE.title }}
                        </th>

                        <th v-if="columns.Guarantees.show">
                            {{ columns.Guarantees.title }}
                        </th>

                        <th v-if="columns.DateManufacture.show">
                            {{ columns.DateManufacture.title }}
                        </th>

                        <th v-if="columns.dateSale.show">
                            {{ columns.dateSale.title }}
                        </th>

                        <th v-if="columns.warrantyExpiration.show">
                            {{ columns.warrantyExpiration.title }}
                        </th>

                        <th v-if="columns.Client.show">
                            {{ columns.Client.title }}
                        </th>

                        <th v-if="columns.Telephone.show">
                            {{ columns.Telephone.title }}
                        </th>

                        <th v-if="columns.Address.show">
                            {{ columns.Address.title }}
                        </th>

                        <th v-if="columns.proStatus.show">
                            {{ columns.proStatus.title }}
                        </th>

                        <th v-if="columns.hCall.show">
                            {{ columns.hCall.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.napeProduct.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.napeProduct"
                                :placeholder="columns.napeProduct.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.gruppa.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.gruppa"
                                :placeholder="columns.gruppa.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.serialNumber.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.serialNumber"
                                :placeholder="columns.serialNumber.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.clasE.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.clasE"
                                :placeholder="columns.clasE.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.Guarantees.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.Guarantees"
                                :placeholder="columns.Guarantees.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.DateManufacture.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.DateManufacture"
                                :placeholder="columns.DateManufacture.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.dateSale.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.dateSale"
                                :placeholder="columns.dateSale.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.warrantyExpiration.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.warrantyExpiration"
                                :placeholder="columns.warrantyExpiration.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.Client.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.Client"
                                :placeholder="columns.Client.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.Telephone.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.Telephone"
                                :placeholder="columns.Telephone.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.Address.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.Address"
                                :placeholder="columns.Address.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.proStatus.show"></th>

                        <th v-if="columns.hCall.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="user in list"
                        :key="user.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">{{ user.id }}</td>

                        <td v-if="columns.napeProduct.show">
                            {{ user.napeProduct }}
                        </td>

                        <td v-if="columns.gruppa.show">
                            {{ user.gruppa }}
                        </td>

                        <td v-if="columns.serialNumber.show">
                            {{ user.serialNumber }}
                        </td>

                        <td v-if="columns.clasE.show">
                            {{ user.clasE }}
                        </td>

                        <td v-if="columns.Guarantees.show">
                            {{ user.Guarantees }}
                        </td>

                        <td v-if="columns.DateManufacture.show">
                            {{ user.DateManufacture }}
                        </td>

                        <td v-if="columns.dateSale.show">
                            {{ user.dateSale }}
                        </td>

                        <td v-if="columns.warrantyExpiration.show">
                            {{ user.warrantyExpiration }}
                        </td>

                        <td v-if="columns.Client.show">
                            {{ user.Client }}
                        </td>

                        <td v-if="columns.Telephone.show">
                            {{ user.Telephone }}
                        </td>

                        <td v-if="columns.Address.show">
                            {{ user.Address }}
                        </td>

                        <td v-if="columns.proStatus.show">
                            <el-button type="success" size="mini">
                                Реализован
                            </el-button>
                        </td>

                        <td v-if="columns.hCall.show">
                            <el-button
                                icon="el-icon-phone-outline"
                                type="warning"
                                @click="drawerHappyCall = true"
                                size="mini"
                            >
                                Позвонить
                            </el-button>
                        </td>
                    </tr>
                </transition-group>
            </table>
        </div>

        <el-dialog
            title="Happy call"
            :visible.sync="drawerHappyCall"
            width="50%"
            center
        >
            <span>
                <TexnoHappyCall></TexnoHappyCall>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import TexnoHappyCall from "./components/texnoHappyCall";
export default {
    name: "texnoHappy",
    components: {
        TexnoHappyCall,
    },
    data() {
        return {
            drawerHappyCall: false,
            filterForm: {
                id: "",
                napeProduct: "",
                gruppa: "",
                serialNumber: "",
                clasE: "",
                Guarantees: "",
                DateManufacture: "",
                dateSale: "",
                warrantyExpiration: "",
                Client: "",
                Telephone: "",
                Address: "",
                proStatus: "",
                data: "",
                hCall: "",
            },
            list: [
                {
                    id: "1",
                    napeProduct: "Samsung RB30N4020B1/WT",
                    gruppa: "Холодильник",
                    serialNumber: "CXZC3454CX",
                    clasE: "A+",
                    Guarantees: "3 года",
                    DateManufacture: "Ташкент",
                    dateSale: "Дилер А",
                    warrantyExpiration: "Магазин А",
                    Client: "Клиент 1",
                    Telephone: "+99899 1234567",
                    Address: "Ташкент, Шайхантаурский  11А",
                    proStatus: "",
                    data: "12.12.2020",
                    hCall: "",
                },
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                napeProduct: {
                    show: true,
                    title: "Наименование",
                    sortable: true,
                    column: "napeProduct",
                },

                gruppa: {
                    show: true,
                    title: "Группа",
                    sortable: true,
                    column: "gruppa",
                },

                serialNumber: {
                    show: true,
                    title: "Серийный номер",
                    sortable: true,
                    column: "serialNumber",
                },

                clasE: {
                    show: true,
                    title: "Класс энергопотребления",
                    sortable: true,
                    column: "clasE",
                },
                Guarantees: {
                    show: true,
                    title: "Гарантия",
                    sortable: true,
                    column: "Guarantees",
                },

                DateManufacture: {
                    show: true,
                    title: "Дата изготовления",
                    sortable: true,
                    column: "DateManufacture",
                },

                dateSale: {
                    show: true,
                    title: "Дата продажи",
                    sortable: true,
                    column: "dateSale",
                },

                warrantyExpiration: {
                    show: true,
                    title: "Дата истечения срока гарантии",
                    sortable: true,
                    column: "warrantyExpiration",
                },

                Client: {
                    show: true,
                    title: "Клиент",
                    sortable: true,
                    column: "Client",
                },

                Telephone: {
                    show: true,
                    title: "Телефон",
                    sortable: true,
                    column: "Telephone",
                },

                Address: {
                    show: true,
                    title: "Адрес",
                    sortable: true,
                    column: "Address",
                },

                proStatus: {
                    show: true,
                    title: "Статус",
                    sortable: true,
                    column: "proStatus",
                },

                hCall: {
                    show: true,
                    title: "Позвонить",
                    sortable: true,
                    column: "hCall",
                },
            },
        };
    },
};
</script>
